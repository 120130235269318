$('[data-toggle="clipboard"]').each((cbIndex, cbEl) => {
  $.fn.clipboard = new ClipboardJS(cbEl)

  $.fn.clipboard.on('success', e => {
    console.log(e)
    $(cbEl).tooltip('show') // Tooltipを表示する
  })

  $.fn.clipboard.on('error', e => {
    console.log(e)
  })

  $(cbEl)
  // Tooltipの設定
  .tooltip({
    title: 'コピーされました',
    trigger: 'manual'
  })
  // Tooltip表示後の動作を設定
  .on('shown.coreui.tooltip', function () {
    setTimeout((() => {
      $(this).tooltip('hide')
    }), 1000)
  })
})
// モーダルエラー応急処置
.tooltip('show').tooltip('hide')
